import styled from "styled-components";
import Box from "./Box";
import { grid } from "styled-system";

const Grid = styled(Box)`
  display: grid;
  ${grid}
`;

export default Grid;
